<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"
          ><img src="images/goback.png"
        /></a>
      </div>
      <div class="headerC">
        <p>订单详情</p>
      </div>
      <div class="headerR"></div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="hbox"></div>
  <div class="kbox"></div>
  <div class="jsaddress">
    <a href="javascript:;" v-if="options.item.payStatus == 'Yes'">
      <div class="jsaddressL" v-if="options.item.orderAddress != null">
        <p class="p6">
          收货人：{{ options.item.orderAddress.name
          }}<span>{{ options.item.orderAddress.mobileNo }}</span>
        </p>
        <p class="p5">
          {{
            options.item.orderAddress.province +
            options.item.orderAddress.city +
            options.item.orderAddress.region +
            options.item.orderAddress.address
          }}
        </p>
      </div>
      <div class="jsaddressL" v-else>
        <p class="p6">亲，您还没有选择送货地址</p>
        <p class="p5">请点击选择地址</p>
      </div>
      <div class="jsaddressR">
        <!-- <img src="images/more.png" /> -->
      </div>
      <div class="clear"></div>
    </a>
    <a href="javascript:;" v-else @click="openAddress">
      <div class="jsaddressL" v-if="options.item.orderAddress != null">
        <p class="p6">
          收货人：{{ options.item.orderAddress.name
          }}<span>{{ options.item.orderAddress.mobileNo }}</span>
        </p>
        <p class="p5">
          {{
            options.item.orderAddress.province +
            options.item.orderAddress.city +
            options.item.orderAddress.region +
            options.item.orderAddress.address
          }}
        </p>
      </div>
      <div class="jsaddressL" v-else>
        <p class="p6">亲，您还没有选择送货地址</p>
        <p class="p5">请点击选择地址</p>
      </div>
      <div class="jsaddressR">
        <img src="images/more.png" />
      </div>
      <div class="clear"></div>
    </a>
  </div>
  <div class="w100">
    <img src="images/addressline.png" />
  </div>
  <div class="clear"></div>
  <div class="kbox"></div>
  <div class="jsdingdan" v-for="g in options.item.goods" :key="g.id">
    <div class="jsdp">
      <a href="javascript:;">
        <span>{{ g.shopName }}</span>
      </a>
    </div>
    <div class="jsxq">
      <div class="jsxq_1">
        <van-image width="3rem" height="3rem" :src="g.goodsPicture" />
      </div>
      <div class="jsxq_2">
        <div class="js_1">
          <p class="p1">{{ g.goodsName }}</p>
          <p class="p2">￥{{ g.preferentialPrice }}</p>
        </div>
        <div class="js_2">
          <p class="p3" v-if="options.item.type == 'SHOPPING'">
            颜色：白色；尺码：L
          </p>
          <p class="p4">×{{ g.quantity }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="jsyf" v-if="options.item.mailPrice > 0">
    <div class="jsyfL">快递运费：</div>
    <div class="jsyfR">
      ￥{{ stringUtils.fixedFormat(options.item.mailPrice) }}
    </div>
  </div>

  <!-- 
    <div class="jsyf">
      <div class="jsyfL">买家留言：</div>
      <div class="jsyfC">订单补充说明...</div>
    </div> -->
  <div class="jshj">
    <div class="jshjp">
      <span class="sp1">合计：</span
      ><span class="sp2"
        >￥{{
          stringUtils.fixedFormat(
            options.item.preferentialAmount + options.item.mailPrice
          )
        }}</span
      >
    </div>
  </div>
  <div class="clear"></div>
  <div class="kbox"></div>
  <div class="kbox"></div>
  <deliver-view :deliverInfo="options.item.orderDeliverGoodsVo"></deliver-view>
  <!-- <div class="jsyhq">
    <div class="jsyhq_1">
      <p class="p1">优惠券</p>
      <p class="p2">无可用</p>
    </div>
    <div class="jsyhq_2">
      <div class="jsjfL">
        <p>积分抵用<span>共150积分，可抵1.5元</span></p>
      </div>
      <div class="jsjfR">
        <div class="gwccheck"></div>
      </div>
    </div>
  </div> -->
  <div class="fbox2"></div>
  <div class="hejiBox jiesuan">
    <div class="heji">
      <div class="heji_3">
        <p>
          总计：<span>￥{{ options.item.preferentialAmount }}</span>
        </p>
      </div>
      <div class="heji_5">
        <a
          href="javascript:;"
          @click="pay"
          v-if="
            options.item.payStatus == 'No' && options.item.status == 'submit'
          "
          >支付</a
        >
        <a
          href="javascript:;"
          @click="options.isDriverShow = true"
          v-if="
            options.item.payStatus == 'Yes' && options.item.refundStatus == 0
          "
          >操作</a
        >
        <!-- <a
          href="javascript:;"
          v-if="
            options.item.payStatus == 'Yes' && options.item.status == 'submit'
          "
          >提醒商家</a
        >
        <a
          href="javascript:;"
          v-if="
            options.item.payStatus == 'Yes' &&
            options.item.status == 'receiving'
          "
          @click="confim"
          >确认收货</a
        >
        <a
          href="javascript:;"
          v-if="
            options.item.payStatus == 'Yes' &&
            options.item.status == 'receivingsuccess' &&
            (options.item.refundStatus == null ||
              options.item.refundStatus == 0)
          "
          @click="refund"
          >售后</a
        > -->
        <router-link
          v-if="options.item.refundStatus == 1"
          :to="'/memberRB/' + options.item.id + '/' + options.item.refundId"
        >
          售后详情
        </router-link>
      </div>
    </div>
  </div>
  <van-popup
    v-model:show="options.isShow"
    position="right"
    :style="{ height: '100vh', width: '80%' }"
  >
    <div class="sx_1">
      地址选择<img @click="options.isShow = false" src="images/close.png" />
    </div>
    <div class="addressbox on" v-for="a in options.addresss" :key="a.id">
      <div class="addressbox_2" style="float: left; width: 15%">
        <div class="ad1" style="margin-top: 1rem">
          <div class="ad1_1" @click="setAddress(a)">
            <div
              :class="
                options.item.orderAddress != null &&
                options.item.orderAddress.addressId == a.id
                  ? 'gwccheck on'
                  : 'gwccheck'
              "
            ></div>
          </div>
        </div>
      </div>
      <div class="addressbox_1" style="float: left; width: 85%">
        <p class="p1">
          收货人：{{ a.name }}<span>{{ a.mobileNo }}</span>
        </p>
        <p class="p2">{{ a.province + a.city + a.region + a.address }}</p>
      </div>
    </div>
    <div class="sx_3">
      <router-link to="/addressList">添加/修改</router-link>
    </div>
  </van-popup>
  <van-action-sheet
    v-model:show="options.isDriverShow"
    :actions="actions"
    cancel-text="取消"
    close-on-click-action
    @select="onSelect"
  />
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import orderApi from "@/api/orderApi";
import addressApi from "@/api/addressApi";
import { reactive } from "vue";
import { Toast, Dialog } from "vant";
import DeliverView from "@/components/order/deliverView.vue";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const options = reactive({
      item: {
        id: "",
        orderAddress: null,
      },
      addresss: [],
      delivers: [],
      orderId: route.params.id,
      isShow: false,
      isDriverShow: false,
    });
    const actions = [];
    function get() {
      orderApi.get({ orderId: options.orderId }).then((res) => {
        if (res.status == 200) {
          options.item = res.result;
          if (options.item.payStatus == "Yes") {
            if(options.item.status == 'submit'){
              actions.push({ name: "提醒商家"});
            }
            if(options.item.status=='receiving'){
              actions.push({ name: "确认收货" });
              actions.push({ name: "售后" });
            }
            if(options.item.status=='receivingsuccess'
              &&(options.item.refundStatus == null ||
              options.item.refundStatus == 0)){
                actions.push({ name: "售后" });
            }
            actions.push({ name: "提醒商家",disabled: true});
            actions.push({ name: "确认收货",disabled: true });
            actions.push({ name: "售后" ,disabled: true});
           
          }
        }
      });
    }
    const onSelect = (item) => {
      console.log(item.name);
      options.isDriverShow = false;
      if (item.name == "确认收货") {
        confim();
      } else if (item.name == "售后") {
        refund();
      } else if(item.name=='提醒商家'){
        Toast('已发送');
      }
    };
    const params = reactive({
      draw: 1,
      start: 0,
      length: 10,
    });
    function openAddress() {
      options.isShow = true;
      search();
    }
    function pay() {
      if (options.item.orderAddress != null) {
        router.push({ path: "/pay/ORDER/" + options.item.id });
      } else {
        Toast.fail("亲，您还没有选择地址");
      }
    }
    async function search() {
      await addressApi.search(params).then((res) => {
        if (res.status == 200) {
          options.addresss = res.data;
        }
      });
    }
    async function setAddress(a) {
      var pa = {
        orderId: options.orderId,
        addressId: a.id,
      };
      await orderApi.setAddress(pa).then((res) => {
        if (res.status == 200) {
          get();
          options.isShow = false;
        }
      });
    }
    const confim = () => {
      Dialog.confirm({
        title: "确认收货",
        message: "亲，您确认要订单【" + options.item.id + "】收货？",
      }).then(() => {
        orderApi.memberConfim({ orderId: options.item.id }).then((res) => {
          get();
          if (res.status != 200) {
            Toast.fail(res.message);
          } else {
            Toast.success("操作完成");
          }
        });
      });
    };
    const refund = () => {
      router.push({ path: "/memberRB/" + options.item.id + "/0" });
    };
    get();
    return {
      options,
      actions,
      search,
      openAddress,
      setAddress,
      pay,
      confim,
      refund,
      onSelect
    };
  },
  components: { DeliverView },
};
</script>

<style>
.sx_1 img {
  float: right;
  width: 1rem;
  margin-top: 0.5rem;
}
</style>