<template>
  <div class="headerbox">
    <div class="header">
      <div class="headerL">
        <a onclick="javascript:history.back(-1)" class="goback"
          ><img src="images/goback.png"
        /></a>
      </div>
      <div class="headerC">
        <p>售后服务</p>
      </div>
      <div class="headerR"></div>
    </div>
  </div>
  <div class="clear"></div>
  <div class="hbox"></div>
  <van-steps :active="options.step">
    <van-step>提交</van-step>
    <van-step>处理中</van-step>
    <van-step>发货</van-step>
    <van-step>完成</van-step>
  </van-steps>

  <div class="pingjiabox1" v-for="goods in options.order.goods" :key="goods.id">
    <div class="pjleft">
      <van-image width="3rem" height="3rem" :src="goods.goodsPicture" />
    </div>
    <div class="pjright">
      <p class="p1">{{ goods.goodsName }}</p>
      <p class="p2"></p>
      <p class="p3">
        ￥{{ stringUtils.fixedFormat(options.order.preferentialAmount) }}
      </p>
    </div>
  </div>
  <div class="clear"></div>
  <div class="sx_2" style="background-color: #fff" v-if="options.item.id == ''">
    <p class="tit">售后类型</p>
    <a
      href="javascript:;"
      :class="options.item.type == 0 ? 'on' : ''"
      @click="options.item.type = 0"
      >未收到拍品</a
    >
    <a
      href="javascript:;"
      :class="options.item.type == 1 ? 'on' : ''"
      @click="options.item.type = 1"
      >拍品有破损</a
    >
    <a
      href="javascript:;"
      :class="options.item.type == 2 ? 'on' : ''"
      @click="options.item.type = 2"
      >拍品与描述不符</a
    >
    <a
      href="javascript:;"
      :class="options.item.type == 3 ? 'on' : ''"
      @click="options.item.type = 3"
      >其他</a
    >
    <a
      href="javascript:;"
      :class="options.item.type == 4 ? 'on' : ''"
      @click="options.item.type = 4"
      >七天无理由退货</a
    >
  </div>
  <div class="sx_2" style="background-color: #fff" v-else>
    <p class="tit">售后类型</p>
    <a href="javascript:;" :class="options.item.type == 0 ? 'on' : ''"
      >未收到拍品</a
    >
    <a href="javascript:;" :class="options.item.type == 1 ? 'on' : ''"
      >拍品有破损</a
    >
    <a href="javascript:;" :class="options.item.type == 2 ? 'on' : ''"
      >拍品与描述不符</a
    >
    <a href="javascript:;" :class="options.item.type == 3 ? 'on' : ''">其他</a>
  </div>
  <div class="clear"></div>
  <div class="pingjiabox2">
    <div class="pjbox2_l">退货理由描述</div>
    <div class="pjbox2_r"></div>
    <div class="clear"></div>
    <div class="yijian">
      <textarea
        name=""
        cols=""
        rows=""
        v-if="options.item.id == ''"
        v-model="options.item.explain"
        placeholder="请输入您的退货理由"
      ></textarea>
      <textarea
        name=""
        cols=""
        rows=""
        v-else
        readonly
        v-model="options.item.explain"
        placeholder="请输入您的退货理由"
      ></textarea>
    </div>
    <div class="picture" v-if="options.item.id == ''">
      <img src="images/pic.png" @click="updateImage" />
      <img
        :src="f.url"
        v-for="(f, index) in options.fileList"
        @click="openAPreview(index)"
        :key="f"
      />
    </div>
    <div class="picture" v-else>
      <img
        :src="f.url"
        v-for="(f, index) in options.fileList"
        @click="openAPreview(index)"
        :key="f"
      />
    </div>
    <deliver-view :deliverInfo="options.item.deliverGoods"></deliver-view>
  </div>
  <div class="clear"></div>
  <div class="fbox2"></div>
  <div class="hejiBox jiesuan">
    <div class="heji">
      <div class="fabiao" v-if="options.item.id == ''">
        <a href="javascript:;" @click="save">提交</a>
      </div>
      <div class="fabiao" v-if="options.item.id != ''&&options.item.status==3&&options.item.deliverGoods==null">
        <a href="javascript:;" @click="openDeliver(options.item)">邮寄退货拍品</a>
      </div>
    </div>
  </div>
  <van-dialog
    v-model:show="wxOptions.isWxMessageShow"
    :showConfirmButton="false"
    title="上传中"
  >
    <div style="width: 100%">
      <div
        style="margin-left: 1rem; margin-bottom: 2rem; float: left; width: 40%"
      >
        <van-circle
          v-model:current-rate="wxOptions.percentage"
          :rate="100"
          start-position="top"
          :speed="5"
          :color="wxOptions.gradientColor"
          :text="wxOptions.percentage + '%'"
        />
      </div>
      <code
        style="float: left; width: 50%; color: #ff2150; line-height: 5rem"
        >{{ wxOptions.message }}</code
      >
    </div>
  </van-dialog>
  <van-popup
    v-model:show="options.isAmoutShow"
    :overlay="false"
    round
    position="bottom"
  >
    <van-number-keyboard
      v-if="options.isAmoutShow"
      v-model="options.item.amount"
      :z-index="2008"
      theme="custom"
      extra-key="."
      :hide-on-click-outside="true"
      close-button-text="完成"
      :show="options.isAmoutShow"
      @blur="options.isAmoutShow = false"
    />
  </van-popup>
  <van-popup
    v-model:show="deliverOptions.isDeliverShow"
    :style="{ height: '48vh',width:'100%' }"
    round
    position="top"
  >
    <div class="xzsize">
      <div class="xzsize0">
        <div class="size1">
          <div class="size1_2">
            
          </div>
          <div class="size1_3">
            <img
              src="images/close.png"
              @click="deliverOptions.isDeliverShow = false"
            />
          </div>
        </div>
        <div class="size2">
          
          <div class="size2_4" @click="deliverOptions.isCompanyShow = true">
            <p class="tit">快递公司</p>
            <div
              class="pric"
              v-if="deliverOptions.deliverParams.logisticsName == ''"
            >
              亲，请选择快递公司
            </div>
            <div class="pric" v-else>
              {{ deliverOptions.deliverParams.logisticsName }}
            </div>
          </div>
          <div class="size2_4">
            <p class="tit">快递单号</p>
            <div class="pric">
              <input
                type="text"
                v-model="deliverOptions.deliverParams.logisticsNo"
              />
            </div>
          </div>
          <div class="size2_4">
            <p class="tit">发货地址</p>
            <div class="pric">
              {{options.order.orderAddress.province +
                options.order.orderAddress.city +
                options.order.orderAddress.region +
                options.order.orderAddress.address}}
            </div>
          </div>
          <div class="size3">
            <a href="javascript:;" @click="deliver">发货</a>
          </div>
        </div>
      </div>
    </div>
  </van-popup>
  <van-popup v-model:show="deliverOptions.isCompanyShow" round position="bottom">
    <van-cascader
      v-model="deliverOptions.deliverParams.logisticsCode"
      title="请选择快递公司"
      :options="deliverOptions.deliverCompany"
      @close="deliverOptions.isCompanyShow = false"
      @finish="onFinish"
    />
  </van-popup>
  <van-image-preview
    v-model:show="options.isImgPreviewShow"
    :startPosition="options.aimgIndex"
    :images="options.aimages"
    @change="onAChange"
  >
    <template v-slot:index>第{{ options.aimgIndex + 1 }}幅</template>
  </van-image-preview>
  <input
    type="file"
    id="rbInput"
    @change="uploadChange"
    accept="image/png,image/jpeg,image/gif,image/jpg"
    style="display: none"
  />
</template>

<script>
import { useRoute } from "vue-router";
import orderApi from "@/api/orderApi";
import { reactive } from "vue";
import wxModel from "@/model/wxModel";
import uploadModel from "@/model/uploadModel";
import refundDeliverModel from "@/model/order/refundDeliverModel";
import { useStore } from "vuex";
import { Toast } from "vant";
import deliverView from '../../../components/order/deliverView.vue';
export default {
  components: { deliverView },
  setup() {
    const route = useRoute();
    const options = reactive({
      orderId: route.params.orderId,
      order: {
        id: "",
        preferentialAmount: 0,
        goods: [],
      },
      item: {
        id: "",
        orderId: "",
        type: 0,
        reason: 0,
        explain: "",
        picInfo: "",
        status: "",
        amount: "",
        orderProductId: "",
      },
      step: 0,
      fileList: [],
      isAmoutShow: false,
      isImgPreviewShow: false,
      aimgIndex: 0,
      aimages: [],
    });
    
    const store = useStore();
    const setFileList = (url) => {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        options.fileList.push(wxOptions.wxOptions.fileUrls);
      } else {
        console.log("url：" + url);
        console.log(JSON.stringify(options.fileList));
        options.fileList.push({ url: url });
      }
    };
    const onAChange = (newIndex) => {
      options.aimgIndex = newIndex;
    };
    const openAPreview = (index) => {
      options.aimgIndex = index;
      options.aimages = [];
      options.fileList.forEach((item) => {
        options.aimages.push(item.url);
      });
      options.isImgPreviewShow = true;
    };
    const { wxOptions, initConfig, chooseDefaultImage } = wxModel(setFileList);
    const { uploadSumit, uploadChange } = uploadModel(setFileList);
    
    wxOptions.fileUrls = [];
    initConfig();
    const getOrder = () => {
      orderApi.get({ orderId: options.orderId }).then((res) => {
        if (res.status == 200) {
          options.order = res.result;
          options.item.amount = options.order.preferentialAmount.toString();
        }
      });
    };

    const save = () => {
      if (options.item.type == 2 || options.item.type == 1) {
        if (options.fileList.length == 0) {
          Toast.fail("亲，请上传图片");
          return;
        }
      }
      if (options.item.explain == "") {
        Toast.fail("亲，请添加理由");
        return;
      }
      // if(parseFloat(options.item.amount)>parseFloat(options.order.preferentialAmount)){
      //   Toast.fail('亲，退款金额超出了退款额度')
      //     return ;
      // }
      options.item.orderId = options.orderId;
      options.item.status = 0;
      options.item.orderProductId = options.order.goods[0].id;
      options.item.picInfo =
        options.fileList.length > 0 ? JSON.stringify(options.fileList) : "";
      orderApi.memberRefundSubmit(options.item).then((res) => {
        if (res.status == 200) {
          options.item = res.result;
          store.commit("setRefund");
          Toast.success("提交完成");
        } else {
          Toast.fail(res.message);
        }
      });
    };
    const get = () => {
      orderApi.getRefund({ id: route.params.Id }).then((res) => {
        if (res.status == 200) {
          options.item = res.result;
          options.fileList =
            options.item.picInfo != "" ? JSON.parse(options.item.picInfo) : [];
          if (options.item.status == 1 || options.item.status == 2) {
            options.step = 1;
          }
          else if (options.item.status == 3) {
            options.step = 2;
          }else if (options.item.status == 4) {
            options.step = 3;
          } else {
            options.step = 0;
          }
        }
      });
    };
    const {deliver, openDeliver,onFinish, deliverOptions } = refundDeliverModel(get);
    const updateImage = () => {
      if (options.fileList.length >= 3) {
        Toast.fail("亲，最多上传三张");
        return;
      }
      let ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        wxOptions.isWxMessageShow = true;
        wxOptions.num = 0;
        wxOptions.message = "开始上传";
        wxOptions.percentage = 0;
        chooseDefaultImage("order", 3);
        options.fileList=wxOptions.fileUrls;
      } else {
        console.log("其他环境中（例如浏览器等等）");
        uploadSumit();
      }
    };
    getOrder();
    if (route.params.Id != "0") {
      get();
    }
    return {
      options,
      wxOptions,
      deliverOptions,
      updateImage,
      save,
      uploadChange,
      openAPreview,
      onAChange,
      openDeliver,
      deliver,
      onFinish
    };
  },
};
</script>

<style>
.sx_2 .on {
  color: #fff !important;
  background: #ff2150 !important;
}
</style>