import filesApi from "../api/filesApi";
import { reactive } from "vue";
import { Toast } from "vant";
import * as imageConversion from 'image-conversion'

const uploadModel=(refresh)=>{
    const uploadOptions=reactive({
        type:'rb',
        filesUrl:""
    })
    const base64ToFile = (urlData, fileName) => {
        let arr = urlData.split(",");
        let mime = arr[0].match(/:(.*?);/)[1];
        let bytes = atob(arr[1]);
        let n = bytes.length;
        let ia = new Uint8Array(n);
        while (n--) {
            ia[n] = bytes.charCodeAt(n);
        }
        return new File([ia], fileName, { type: mime });
    };
    const uploadSumit = (type) => {
        uploadOptions.type = type;
        let oBtn = document.getElementById("rbInput");
        oBtn.click();
    };
    const uploadChange = (e) => {
        const input = e.target;
        let files = input.files;
        let content = null;
        let readfile = new FileReader();
        Toast.loading({
            forbidClick: true,
            message: "上传中......",
        });
        if (files[0] != undefined) {
            content = readfile.readAsDataURL(files[0], "UTF-8");
            readfile.onload = async (event) => {
                content = event.target.result;
                var fileName=new Date().getTime() + ".png";
                let blod = base64ToFile(content, fileName);
                const blobs = await imageConversion.compressAccurately(blod, 600);
                let form=new FormData();
                form.append("upfile",blobs,fileName);
                form.append("type",uploadOptions.type);
                filesApi.uploadFormImg(form)
                    .then((res) => {
                        if (res.status == 200) {
                            refresh(res.result.url);
                        } else {
                            Toast.fail(res.message);
                        }
                        Toast.clear();
                    });
            };
        }
    };
    return {
        uploadOptions,
        uploadSumit,
        uploadChange
    }
}
export default uploadModel;