import { Toast, Dialog } from 'vant';
import orderApi from "@/api/orderApi";
import { reactive } from "vue";

const refundDeliverModel = (refresh) => {
  const deliverOptions = reactive({
    deliverParams: {
      orderId: "",
      logisticsNo: "",
      logisticsName: "",
      logisticsCode: "",
      from: ''
    },
    company: null,
    deliverCompany: [],
    delivers:[],
    item: {},
    isDeliverShow: false,
    isCompanyShow: false,
  })
  const openDeliver = (item) => {
    deliverOptions.item = item;
    deliverOptions.deliverParams = {
      refundId: item.id,
      logisticsNo: "",
      logisticsName: "",
      logisticsCode: "",
      from: ''
    };
    deliverOptions.isDeliverShow = true;
  };
  const deliver = () => {
    if (deliverOptions.deliverParams.logisticsName == "") {
      Toast.fail("亲，请选择快递公司！");
      return;
    }
    if (deliverOptions.deliverParams.logisticsNo == "") {
      Toast.fail("亲，快递单号不能为空！");
      return;
    }
    Dialog.confirm({
      title: "邮寄退货拍品",
      message: "亲，您确认要邮寄退货拍品？",
    }).then(() => {
      orderApi.refundDeliver(deliverOptions.deliverParams).then((res) => {
        if (res.status == 200) {
          refresh();
          deliverOptions.isDeliverShow = false;
        } else {
          Toast.fail(res.message);
        }
      });
    });
  };
  const getAllCompany = () => {
    orderApi.getAllCompany().then(res => {
      if (res.status == 200) {
        deliverOptions.deliverCompany = res.result;
      }
    });
  }
  const onFinish = ({ selectedOptions }) => {
    deliverOptions.isCompanyShow = false;
    deliverOptions.deliverParams.logisticsName = selectedOptions.map((option) => option.text).join('/');
  };
  getAllCompany();
  return {
    deliverOptions,
    openDeliver,
    deliver,
    onFinish
  }

}

export default refundDeliverModel;